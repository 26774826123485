/*
=============== 
Variables
===============
*/

:root {
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --max-width: 1170px;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  line-height: 1.5;
  font-size: 0.875rem;
  background-color: #6b654c;
}
html,
body {
  max-width: 100%;
  overflow-x: hidden;
}
* {
  font-family: "Merriweather Sans", "arial";
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
.show {
  opacity: 1;
}
.hidden {
  display: none;
}
